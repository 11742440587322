import moment from "moment";
import { CalendarBooking } from "../models/booking/calendar-booking";
import { MakeReservationModel } from "../models/booking/make-reservation-model";
import { SmoobuResponse } from "../models/smoobu";

export const SMOOBU_DATE_FORMAT = 'YYYY-MM-DD';
export const SMOOBU_CHANNEL_ID = 1382596;

export const checkAvailability = async (from: moment.Moment, to: moment.Moment, camperIds: number[]): Promise<SmoobuResponse> => {
    let query = `from=${from.format(SMOOBU_DATE_FORMAT)}&to=${to.format(SMOOBU_DATE_FORMAT)}`;
    camperIds.forEach(id => query = `${query}&camperIds=${id}`);
    const result = await fetch(`/.netlify/functions/checkAvailability?${query}`)
        .then(res => res.json());
    return result;
}

export const makeSmoobuReservation = async (model: MakeReservationModel) => {
    const body: any = Object.assign(model, {});
    body.from = model.from.format(SMOOBU_DATE_FORMAT);
    body.to = model.to.format(SMOOBU_DATE_FORMAT);
    body.channelId = SMOOBU_CHANNEL_ID;
    body.phone = model.phone;
    body.priceStatus = 0; //open/not paid
    const result = await fetch(`/.netlify/functions/makeReservation`,
        {
            method: 'POST',
            body: JSON.stringify(model)
        })
        .then(res => res.json());
    return result;
}