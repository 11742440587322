import React from "react";
import styled from "styled-components";
import { green, navy } from "../../shared/colors";

const Container = styled.div<{ active: boolean }>`
    width: 100%;
    max-width: 600px;
    margin: auto;
    transition: all 1s cubic-bezier(0.5,0.5,0.5,0.5);
    opacity: 0.5;
    cursor: pointer;
    &+* {
        overflow: hidden;
        max-height: 0;
        transition: all 1s cubic-bezier(0.5,0.5,0.5,0.5);
    }

    ${props => props.active ? `
        opacity: 1;
        &+* {
            max-height: 9999px;
        }` : ``}

`;

const Header = styled.h1`
    border-bottom: 1px solid ${navy};
    width: 100%;
    position: relative;
`;

const Check = styled.div<{ done: boolean }>`
    background: #5cb85c;
    border-radius: 100%;
    opacity: ${props => props.done ? 1 : 0};
    transition: all 0.5s ease-in-out;
    height: 25px;
    width: 25px;
    position: absolute;
    right: 0;
    top: 0;
    &::after{
        content: '';
        height: 12px;
        width: 6px;
        position: absolute;
        border: 3px solid white;
        transform: rotate(45deg);
        border-width: 0 3px 3px 0;
        margin: 4px 8px;
    }
`;

const Chevron = styled.div<{ active: boolean, done: boolean }>`
        transition: all 0.5s ease-in-out;
        opacity: ${props => props.done ? 0 : 1};
        &::after{
            position: absolute;
            content: '';
            height: 12px;
            width: 12px;
            border: 3px solid ${navy};
            border-width: 3px 3px 0 0;
            right: 5px;
            top: 5px;
            transition: all 0.5s ease-in-out;
            transform: rotate(${props => props.active ? 135 : 45}deg);
            transform: rotate(${props => props.active ? 135 : 45}deg);
        }
`;


type BookingSectionHeaderProps = {
    name: string,
    active: boolean,
    done: boolean,
    onClick: () => void
}

const BookingSectionHeader = ({ name, done, active, onClick }: BookingSectionHeaderProps) => {
    return <Container onClick={onClick} active={active}> <Header>
        {name}
        <Check done={done}></Check>
        <Chevron active={active} done={done} />
    </Header>
    </Container>;
}

export default BookingSectionHeader;