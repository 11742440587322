import React, { Component } from "react";
import styled from "styled-components";
import { Camper, CamperAvailability, SubmitButton } from "../../pages/booking";
import { Link } from "gatsby";
import { green, navy } from "../../shared/colors";
import { toast } from "react-toastify";

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    max-width: 500px;
    width: calc(100vw - 20px);
    margin: auto;
`;

const Terms = styled.div` 

`;

const Intro = styled.p`
    background: white;
    border: 1px solid ${navy};
    border-radius: 3px;
    text-align: center;
    padding: 15px;
    margin-top: 0;
    font-size: 1.1em;
`;

const Details = styled.div`
    display: flex;
    flex-direction: column;
    > div {
        display: flex;
        margin-bottom: 10px;
        > label {
            font-weight: 500;
            min-width: 120px;
        }
        > span {
            
        }
    }
`;

const Loading = styled.div<{ show: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    ${props => props.show ? `
        background: rgba(0,0,0,0.1);
        z-index: 100;

        > div {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            border: 10px solid transparent; /* Light grey */
            border-top: 10px solid ${green}; /* Blue */
            top: calc(50% - 50px);
            left: calc(50% - 50px);
            position: absolute;
    
            animation: spin 0.5s linear infinite;
        }
    ` : ''}

    @keyframes spin {
        0%  {transform: rotate(0deg);}
        100% {transform: rotate(360deg);}   
    }
`;

type SummaryState = {
    agreed: boolean,
    loading: boolean
}

type SummaryProps = {
    complete: () => void,
    from: moment.Moment | null,
    to: moment.Moment | null,
    camper: CamperAvailability | null,
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    finishedSubmitting: boolean
}

export class Summary extends Component<SummaryProps, SummaryState> {
    constructor(props: SummaryProps) {
        super(props);
        this.state = {
            agreed: false,
            loading: false
        }
        this.submitClick = this.submitClick.bind(this);
        this.agreeClicked = this.agreeClicked.bind(this);
    }

    submitClick() {
        this.setState({loading: true})
        this.props.complete();
    }


    agreeClicked() {
        const { agreed } = this.state;
        this.setState({
            agreed: !agreed
        })
    }

    render() {
        const { from, to, camper, firstName, lastName, email, phone, finishedSubmitting } = this.props;
        let { loading } = this.state;
        if (finishedSubmitting) {
            loading = false;
        }

        return (
            <Container>
                <Intro>Almost there! Review your details and confirm.</Intro>
                <Details>
                    <div>
                        <label>Selected Date:</label><span>{from?.format('DD MMM YY')} - {to?.format('DD MMM YY')}</span>
                    </div>
                    <div>
                        <label>Name:</label><span>{firstName} {lastName}</span>
                    </div>
                    <div>
                        <label>Contact:</label><span>{email} {phone}</span>
                    </div>
                    <div>
                        <label>eDub:</label><span style={{color: camper?.colour}}>{camper?.name}</span>
                    </div>
                    <div>
                        <label>Total Price:</label><span>£{camper?.price.toFixed() || 0}</span>
                    </div>
                </Details>
                <SubmitButton onClick={this.submitClick}>
                    confirm
                </SubmitButton>
                <Loading show={loading}>
                    <div></div>
                </Loading>
            </Container>);
    }
}

export default Summary;

