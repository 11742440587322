import React, { Component } from "react";
import styled from "styled-components";
import {  SubmitButton } from "../../pages/booking";
import ReactMarkdown from "react-markdown";
import { Link } from "gatsby";

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    max-width: 500px;
    width: calc(100vw - 20px);
    margin: auto;
    > div {
        display: flex;
        max-width: 500px;
        align-self: start;
        width: calc(100vw - 20px);
    }
`;

const DetailsForm = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    > div {
        margin-bottom: 10px;
    }
`;

const FormInput = styled.input`
  margin: 0 0 10px 0;
  padding: 8px;
  border: solid 1px #dddddd;
  border-radius: 10px;
  font: inherit;
  flex: 1;
  &:focus {
    outline: dotted 1px #000000;
  }
`;

const FormLabel = styled.label`
  font-size: 0.8rem;
  margin: 5px 0px;
  display: flex;
`;

type DetailsState = {
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
}

type DetailsProps = {
    complete: (details: DetailsState) => void
}

export class Details extends Component<DetailsProps, DetailsState> {
    constructor(props: DetailsProps) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            phone: ''
        }
        this.onFirstNameChange = this.onFirstNameChange.bind(this);
        this.onLastNameChange = this.onLastNameChange.bind(this);
        this.onEmailChange = this.onEmailChange.bind(this);
        this.onPhoneChange = this.onPhoneChange.bind(this);
        this.submitClick = this.submitClick.bind(this);
    }

    onFirstNameChange(event: any) {
        this.setState({ firstName: event.target.value });
    }

    onLastNameChange(event: any) {
        this.setState({ lastName: event.target.value });
    }

    onEmailChange(event: any) {
        this.setState({ email: event.target.value });
    }

    onPhoneChange(event: any) {
        this.setState({ phone: event.target.value });
    }

    submitClick() {
        this.props.complete(this.state);
    }

    render() {
        const {firstName, lastName, email, phone} = this.state;
        const canSubmit = !!firstName &&!!lastName &&!!email &&!!phone 
        return (
            <Container>
                <DetailsForm>
                    <div>
                        <FormLabel>First Name:</FormLabel>
                        <FormInput required type="text" name="firstName" value={this.state.firstName} onChange={this.onFirstNameChange} />
                    </div>
                    <div>
                        <FormLabel>Last Name:</FormLabel>
                        <FormInput required type="text" name="lastName" value={this.state.lastName} onChange={this.onLastNameChange} />
                    </div>
                    <div>
                        <FormLabel>Email:</FormLabel>
                        <FormInput required type="email" name="email" value={this.state.email} onChange={this.onEmailChange} />
                    </div>
                    <div>
                        <FormLabel>Phone Number:</FormLabel>
                        <FormInput required type="tel" name="email" value={this.state.phone} onChange={this.onPhoneChange} />
                    </div>
                </DetailsForm>
                <SubmitButton onClick={this.submitClick} disabled={!canSubmit}>
                    confirm details
                </SubmitButton>
            </Container>);
    }
}

export default Details;

