import React, { Component } from "react";
import styled from "styled-components";
import { Camper, CamperAvailability, SubmitButton } from "../../pages/booking";
import ReactMarkdown from "react-markdown";
import { navy } from "../../shared/colors";

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    max-width: 600px;
    width: calc(100vw - 20px);
    margin: auto;
    > div {
        display: flex;
        max-width: 600px;
        align-self: start;
    }
`;

const ChooseDates = styled.a`
    margin: 5px 20px;
    &:before {
        position: absolute;
        height: 5px;
        width: 5px;
        transform: rotate(45deg);
        content: '';
        margin-left: -12px;
        margin-top: 6px;
        border-width: 2px 0 0 2px;
        border-style: solid;
    }

    &:after {
        position: absolute;
        height: 5px;
        width: 5px;
        transform: rotate(45deg);
        content: '';
        margin-left: 5px;
        margin-top: 6px;
        border-width: 2px 0 0 2px;
        border-style: solid;
    }

`;

const CamperContainer = styled.div<{ colour: string, selected: boolean }>`
    border-radius: 3px;
    border: 1px solid black;
    background: white;
    margin: 4px;
    box-shadow: ${props => props.selected ? `0 0 4px 0 ${navy}` : 'none'};
    height: 60px;
    flex: 1;
    cursor: pointer;
    display: flex;
    position: relative;
    font-size: 0.8rem;
    > div {
        display: flex;
        height: 100%;
        padding: 0 0 0 20px;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        border-left: 5px solid ${props => props.colour};
        align-items: start;
    }
    > span::after {
        content: '';
        height: 10px;
        width: 10px;
        right: 10px;
        position: absolute;
        top: calc(50% - 5px);
        border: 3px solid rgba(0,0,0,0.2);
        border-width: 3px 3px 0 0;
        transform: rotate(45deg);
    }
`;

const CamperName = styled.div`
    font-size: 1.6rem;
`;

const CamperIsAvailabile = styled.div<{ isAvailable: boolean }>`
    position: relative;
    &::before {
        content: '';
        position: absolute;
        height: 6px;
        width: 6px;
        margin: 6px 0;
        left: -11px;
        border-radius: 100%;
        background: ${props => props.isAvailable ? '#5cb85c' : '#d9534f'};
        box-shadow: 0 0 4px 1px ${props => props.isAvailable ? '#5cb85c' : '#d9534f'};
    }
`;

const Campers = styled.div`
    flex: 0 0 150px;
`;

const CamperInfo = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
`;

const CamperImg = styled.img`
    height: auto;
    width: 100%;
    object-fit: cover;
`;

const CamperImgContainer = styled.div`
    height: 300px;
    width: 100%;
`;

const PriceConfirm = styled.div`
    margin: auto;
    margin-top: 20px;
    font-size: 1.2rem;
`;

const NoCamperSelected = styled.div`

`;

type CampersState = {
    activeCamper: CamperAvailability | null;
}

type CampersProps = {
    complete: (camper: CamperAvailability) => void,
    campers: CamperAvailability[]
}

export class CamperSelection extends Component<CampersProps, CampersState> {
    constructor(props: CampersProps) {
        super(props);
        this.state = {
            activeCamper: null
        }
        this.camperClick = this.camperClick.bind(this);
    }

    camperClick(camper: CamperAvailability) {
        const { activeCamper } = this.state;
        if (activeCamper?.smoobuId == camper.smoobuId) {
            this.setState({
                activeCamper: null
            });
        } else {
            this.setState({
                activeCamper: camper
            });
        }
    }

    render() {
        const { campers, complete } = this.props;
        const { activeCamper } = this.state;
        return (
            <Container>
                <div>
                    <Campers>
                        {campers.map((camper, index) =>
                        (<CamperContainer selected={activeCamper?.smoobuId == camper.smoobuId} colour={camper.colour} key={index} onClick={() => this.camperClick(camper)}>
                            <div>
                                <CamperName>{camper.name}</CamperName>
                                <CamperIsAvailabile isAvailable={camper.available}>
                                    {camper.available ? 'Available' : 'Unavailable'}

                                    {camper.available ? <span>: £{camper?.price.toFixed() || 0}</span> : <></>}
                                </CamperIsAvailabile>
                            </div>
                            <span></span>
                        </CamperContainer>))}
                    </Campers>
                    <CamperInfo>
                        {!!activeCamper
                            ? (<>
                                <ReactMarkdown>{activeCamper.info}</ReactMarkdown>
                            </>)
                            : <NoCamperSelected></NoCamperSelected>}


                    </CamperInfo>
                </div>

                {!!activeCamper
                    ? <CamperImgContainer>
                        <CamperImg src={activeCamper.image}></CamperImg>
                    </CamperImgContainer>
                    : <></>}

                {!!activeCamper ?
                    <>
                        <PriceConfirm>Price for the selected dates: £{activeCamper?.price.toFixed() || 0}</PriceConfirm>
                        <SubmitButton disabled={!activeCamper.available} onClick={() => complete(activeCamper!)}>
                            Select {activeCamper!.name}
                        </SubmitButton>
                    </> : <></>}
            </Container>);
    }
}

export default CamperSelection;

