import React, { Component } from "react";
import styled from "styled-components";
import { Moment } from 'moment';
import * as moment from 'moment';
import { navy, green } from '../../shared/colors';
import { checkAvailability, SMOOBU_DATE_FORMAT } from "../../services/smoobu.service";
import { SmoobuResponse, SmoobuReservation, SmoobuRate } from "../../models/smoobu";
import { CalendarBooking } from "../../models/booking/calendar-booking";
import { dateRangeOverlaps } from "../../utils/date-helper";
import { Camper, CamperAvailability, SubmitButton } from "../../pages/booking";
import { toast } from "react-toastify";
import { SmoobuAvailabilities } from "../../models/smoobu/smoobu-availabilities";

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    max-width: 343px;    
    width: calc(100vw - 20px);
    margin: auto;
    > div {
        display: flex;
    }
`;

const CalendarContainer = styled.div`
    flex: 1;
`;

const Header = styled.div`
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    > div {
        display: flex;
        justify-content: center;
    }
`;

const Month = styled.div`
    flex: 1;
    text-align: center;
    min-width: 50px;
`;

const Chevron = styled.div<{ enable: boolean }>`
    width: 10px;
    height: 10px;
    border-color: ${navy};
    border-style: solid;
    border-width: 0 0 3px 3px;
    cursor: pointer;
    margin: 0 30px;
    ${props => props.enable
        ? ``
        : `
        opacity: 0.6;
        cursor: not-allowed;
    `}
`;

const Previous = styled(Chevron)`
    transform: rotate(45deg);
`;


const Next = styled(Chevron)`
    transform: rotate(-135deg);
`;

const Weekdays = styled.div`
    padding-top: 10px;

    > * {
        flex: 1;
        text-align: center;
    }
`;
const Dates = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: stretch;
`;
const Week = styled.div`
    display: flex;
`;

const Date = styled.div<{ currentMonth: boolean, selected: boolean, canClick: boolean, isStart: boolean, isEnd: boolean }>`
    text-align: center;
    flex: 1;
    padding: 5px;
    margin: 2px 0;
    transition: all 0.2s ease-in-out;
    user-select: none;

    border-radius: ${props => (props.isStart && props.isEnd) ? '5px' : props.isStart ? '5px 0 0 5px' : props.isEnd ? '0 5px 5px 0' : props.selected ? '0' : '5px'};
    ${props => !props.currentMonth
        ? `
        opacity: 0.6;
    `
        : ''}
    ${props => props.selected
        ? `
        background: ${green};
        color: white;
    `
        : ''}
    ${props => props.canClick
        ? `
        cursor: pointer;

        &:hover {
            background: rgba(0,0,0,0.5);
            color: white;
        }
    `
        : `
        opacity: 0.6;
    `}
`;

const Inputs = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const DateInput = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    margin: 5px 0;
    > input {
        margin-top: 5px;
    }
`;

const Loading = styled.div<{ show: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    ${props => props.show ? `
        background: rgba(0,0,0,0.1);
        z-index: 100;

        > div {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            border: 10px solid transparent; /* Light grey */
            border-top: 10px solid ${green}; /* Blue */
            top: calc(50% - 50px);
            left: calc(50% - 50px);
            position: absolute;
    
            animation: spin 0.5s linear infinite;
        }
    ` : ''}

    @keyframes spin {
        0%  {transform: rotate(0deg);}
        100% {transform: rotate(360deg);}   
    }
`;

const Price = styled.div`
    margin-top: 15px;
    font-size: 1.2em;
    font-weight: 500;
`;

type CalendarState = {
    from: Moment | null,
    to: Moment | null,
    currentMonth: Moment,
    loading: boolean,
    bookings: CalendarBooking[],
    price: number
}

type CalendarProps = {
    complete: (from: moment.Moment, to: moment.Moment, availableCampers: CamperAvailability[]) => void,
    campers: Camper[]
}

export class Calendar extends Component<CalendarProps, CalendarState> {
    constructor(any: CalendarProps) {
        super(any);
        this.state = {
            from: null,
            to: null,
            currentMonth: moment.default().startOf('M'),
            loading: false,
            bookings: [],
            price: 0
        }
        this.previous = this.previous.bind(this);
        this.next = this.next.bind(this);
        this.renderDays = this.renderDays.bind(this);
        this.onDateClick = this.onDateClick.bind(this);
        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
        this.checkAvailability = this.checkAvailability.bind(this);
    }

    previous() {
        const { currentMonth } = this.state;
        if (currentMonth > moment.default().startOf('M')) {
            currentMonth.add(-1, 'M');
            this.setState({currentMonth: currentMonth});
        }
    }

    next() {
        const { currentMonth } = this.state;
        currentMonth.add(1, 'M');
        this.setState({currentMonth: currentMonth});
    }

    renderHeader() {
        const { currentMonth } = this.state;
        const canGoBack = currentMonth > moment.default().startOf('M');
        const mom = moment.default();
        return (
            <Header>
                <div>
                    <Previous onClick={this.previous} enable={canGoBack}></Previous>
                    <Month>{currentMonth.format('MMM YY')}</Month>
                    <Next onClick={this.next} enable={true}></Next>
                </div>
                <Weekdays>
                    <div>{mom.weekday(0).format('dd')}</div>
                    <div>{mom.weekday(1).format('dd')}</div>
                    <div>{mom.weekday(2).format('dd')}</div>
                    <div>{mom.weekday(3).format('dd')}</div>
                    <div>{mom.weekday(4).format('dd')}</div>
                    <div>{mom.weekday(5).format('dd')}</div>
                    <div>{mom.weekday(6).format('dd')}</div>
                </Weekdays>
            </Header>);
    }

    renderDays() {
        const { currentMonth, from, to, bookings } = this.state;
        const week = moment.default(currentMonth);
        const firstWeek = this.getWeekCells(week.startOf('week'), currentMonth, from, to, bookings);
        const weeks = [firstWeek];

        while (week.add(1, 'day').isSame(currentMonth, 'month')) {
            weeks.push(this.getWeekCells(week, currentMonth, from, to, bookings));
        }

        return <Dates>
            {weeks}
        </Dates>
    }

    onDateClick = (day: Moment, from: Moment | null, to: Moment | null) => {
        if (from == null && to == null) {
            this.setState({ from: day });
        } else if (from != null && from.isBefore(day, 'day')) {
            this.setState({ to: day }, () => {
                this.getPrice();
            });
        } else if (from != null && from.isSame(day, 'day')) {
            this.setState({ from: null, to: null });
        }
    };

    getWeekCells(date: Moment, currentMonth: Moment, from: Moment | null, to: Moment | null, bookings: CalendarBooking[]) {
        return (<Week>
            {this.getDateCell(date, currentMonth, from, to, bookings)}
            {this.getDateCell(date.add(1, 'day'), currentMonth, from, to, bookings)}
            {this.getDateCell(date.add(1, 'day'), currentMonth, from, to, bookings)}
            {this.getDateCell(date.add(1, 'day'), currentMonth, from, to, bookings)}
            {this.getDateCell(date.add(1, 'day'), currentMonth, from, to, bookings)}
            {this.getDateCell(date.add(1, 'day'), currentMonth, from, to, bookings)}
            {this.getDateCell(date.add(1, 'day'), currentMonth, from, to, bookings)}
        </Week>);
    }

    getDateCell(date: Moment, currentMonth: Moment, from: Moment | null, to: Moment | null, bookings: CalendarBooking[]) {
        const isStart = (from?.isSame(date, 'day') ?? false);
        const isEnd = to != null ? to?.isSame(date, 'day') ?? false : isStart;
        const selected = (from?.isSameOrBefore(date, 'day') ?? false)
            && ((to == null && isStart) || (to?.isSameOrAfter(date, 'day') ?? false));
        const clone = date.clone();
        let canClick = (from?.isSameOrBefore(date, 'day') ?? true);
        canClick &&= date.isAfter(moment.default(), 'day');
        return (
            <Date
                onClick={() => canClick ? this.onDateClick(clone, from, to) : {}}
                currentMonth={date.isSame(currentMonth, 'month')}
                selected={selected}
                isStart={isStart}
                isEnd={isEnd}
                canClick={canClick}>
                {date.format('D')}
            </Date>
        );
    }

    handleFromChange(ev: any) {
        const from = moment.default(ev.target.value);
        this.setState({
            from: from,
            currentMonth: from.clone().startOf('M')
        });
    }

    handleToChange(ev: any) {
        this.setState({ to: moment.default(ev.target.value) }, () => {
            this.getPrice();
        });
    }

    getPrice() {
        this.setState({loading: true})

        const {from, to} = this.state;
        checkAvailability(from!, to!, this.props.campers.map(c => c.smoobuId))
            .then((res: SmoobuAvailabilities) => {
                let price = 0;
                if (res.data.availableApartments != null && res.data.availableApartments.length > 0) {
                    price = Object.keys(res.data.prices)
                        .map(camperId => res.data.prices[+camperId].price)
                        .sort((a, b) => a - b)
                        [0];
                }

                this.setState({
                    price: price
                });
            })
            .catch((err) => {
                console.log(err);
                toast.error('An error occurred fetching the pricing data.');
            })
            .finally(() =>{
                this.setState({loading: false});
            });
    }

    checkAvailability() {
        const { from, to } = this.state;
        if (!!from && !!to) {
            this.setState({
                loading: true
            });

            checkAvailability(from!, to!, this.props.campers.map(c => c.smoobuId))
                .then((result: SmoobuAvailabilities) => {
                    const availableCampers = this.props.campers.filter(camper => {
                        const camperId = +camper.smoobuId;
                        return result.data.availableApartments.some(ap => ap == camperId);
                    });
                    const campers: CamperAvailability[] = this.props.campers.map(c => {
                        const isAvailable = availableCampers.some(ac => +ac.smoobuId == +c.smoobuId);
                        const price = isAvailable ? result.data.prices[+c.smoobuId].price : 0;
                        return Object.assign(c, { available: isAvailable, price: price });
                      });
                      
                    this.setState({loading: false});

                    if (availableCampers.length > 0) {
                        this.props.complete(from, to, campers);
                    } else {
                        toast.error(`We're sorry - there are no eDubs available for the selected dates.`)
                    }
                });
        }
    }

    render() {
        const { from, to, loading, price} = this.state;
        const fromVal = from?.format('YYYY-MM-DD') || '';
        const toVal = to?.format('YYYY-MM-DD') || '';
        const numberOfNights = to?.diff(from, 'day') ?? 0;
        return (
            <Container>
                <div>
                    <CalendarContainer>
                        {this.renderHeader()}
                        {this.renderDays()}
                    </CalendarContainer>
                    <Inputs>
                        <DateInput>
                            From
                            <input type="date" value={fromVal} onChange={this.handleFromChange} />
                        </DateInput>
                        <DateInput>
                            To
                            <input type="date" value={toVal} onChange={this.handleToChange} />
                        </DateInput>
                    </Inputs>
                </div>
                {price > 0 ? <Price >
                    {numberOfNights} night(s) from £{price.toFixed()}
                </Price> : <></>}
                {!!from && !!to ? <SubmitButton onClick={this.checkAvailability}>
                    Confirm dates
                </SubmitButton> : <></>}
                <Loading show={loading}>
                    <div></div>
                </Loading>
            </Container>);
    }
}

export default Calendar;

